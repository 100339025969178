// imports
import Link from 'next/link';

// components
import { Icon as AccessibilityIcon } from '@/components/AccessibilityIcons/AccessibilityIcons';

// svg’s
import NextIcon from '@/public/svg/caret-next.svg';

// styles
import styles from './SeeAllVideosWithAudioDescription.module.scss';
interface SeeAllVideosWithAudioDescriptionProps {
  className?: string;
}
const SeeAllVideosWithAudioDescription = (props: SeeAllVideosWithAudioDescriptionProps) => {
  const {
    className
  } = props;
  let classes = styles.video_audio_description;
  if (className) {
    classes += ` ${className}`;
  }
  return <Link href="/search/?q=audio+description" className={classes} data-sentry-element="Link" data-sentry-component="SeeAllVideosWithAudioDescription" data-sentry-source-file="SeeAllVideosWithAudioDescription.tsx">
      See all videos with Audio Description
      <AccessibilityIcon text="AD" showAdIcon={true} data-sentry-element="AccessibilityIcon" data-sentry-source-file="SeeAllVideosWithAudioDescription.tsx" />
      <NextIcon className={styles.caret_prev_icon} data-sentry-element="NextIcon" data-sentry-source-file="SeeAllVideosWithAudioDescription.tsx" />
    </Link>;
};
export default SeeAllVideosWithAudioDescription;